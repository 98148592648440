import React, {useEffect, useState} from 'react';
import {fetchP5, fixupTime, getNewDate, pickString} from "../Utils";
import {Link, useNavigate} from 'react-router-dom';
import {
    Checkbox,
    FormControlLabel,
    FormGroup, Paper,
    Select, TableCell,
    ToggleButton,
    ToggleButtonGroup
} from "@mui/material";
import Button from "@mui/material/Button";
import FootballEventCell from "../components/FootballEventCell";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import MenuItem from "@mui/material/MenuItem";

function FootballMakePicks(props) {
    
    let navigate = useNavigate();    
    const [isLoading, setIsLoading] = useState(true);
    const [config, setConfig] = useState({});
    const [currentPicks, setCurrentPicks] = useState({});
    const [pendingPicks, setPendingPicks] = useState({});
    const [submitPicksResult,setSubmitPicksResult] = useState("");
    const [availablePicks, setAvailablePicks] = useState([]);
    const [events, setEvents] = useState([]);
    const [eventFilter, setEventFilter] = useState('ALL');
    
    const fetchData = () => {
        fetchP5(props.getState().userName, props.getState().password, '/api/v1/football/makepicksdata', 'footballmakepicks', props)
            .then(data => {
                const config = props.getState().footballmakepicks.data.config.data[0];
                setConfig(config);
                setAvailablePicks(props.getState().footballmakepicks.data.available_picks.data);
                processEvents(eventFilter, props.getState().footballmakepicks.data.events.data);
                if (props.getState().footballmakepicks.data.current_picks.data.length) {
                    setCurrentPicks(props.getState().footballmakepicks.data.current_picks.data[0]);
                    setPendingPicks(props.getState().footballmakepicks.data.current_picks.data[0]);
                }

                if (isLoading) {
                    setIsLoading(false);
                }
            });
    };

    useEffect(() => {
        document.title = "Pick 5 Football Picks";
        if (!props.getState().isLoggedIn) {
            return navigate("/");
        }
        if (isLoading) {
            fetchData();
        }
    }, []);

    function processEvents(currentFilter, eventData) {
        setEvents(eventData
            .filter((event) => currentFilter === 'ALL' || event.SportAbbrev === currentFilter)
            .reduce((acc, event) => {
                const day = getNewDate(event.Day);
                const dayOfWeek = day.toLocaleDateString('en-us', {weekday: 'long', day: 'numeric', month:'numeric'});
                if (!acc[dayOfWeek]) {
                    acc[dayOfWeek] = [];
                }
                acc[dayOfWeek].push(event);
                return acc;
        }, {}));
    }
    
    function renderLoading() {
        return <div>Loading</div>;
    }

    function pick_data_value(pick_data) {
        return decodeURI(pick_data).replace("&quot", "'");
    }

    function handleSubmitPicksClick() {
        let url = `/api/v1/football/makepicks?`;
        [...Array(5).keys()].forEach((pickNum) => {
            const pick = pendingPicks[`Pick${pickNum+1}`] || '';
            const event_id = pendingPicks[`event_id${pickNum+1}`] || '';
            const locked = (pendingPicks[`Spread${pickNum+1}`] !== null && pendingPicks[`Spread${pickNum+1}`] !== undefined) ? 1 : 0;
            if (pickNum) {
                url += '&';
            }
            url += `pick${pickNum+1}=` + encodeURIComponent(pick_data_value(pick)) + `&event_id${pickNum+1}=` + event_id + `&lock${pickNum+1}=` + locked;
        });
        
        fetchP5(props.getState().userName, props.getState().password, url, 'footballmakepicks', props)
            .then(data => {
                if (!data.error) {
                    setCurrentPicks({...pendingPicks});
                }
                setSubmitPicksResult(data.error ? data.error.message : "Success");
                setTimeout(() => {
                    setSubmitPicksResult('');
                }, 5000);
            }
        );
    }

    function handleCostanzaClick() {
        const picks = {...pendingPicks};
        [...Array(5).keys()].forEach((pickNum) => {
            if (pendingPicks[`event_id${pickNum+1}`] && pendingPicks[`Spread${pickNum+1}`] === null) {
                let event = props.getState().footballmakepicks.data.events.data.find((event) => Number(event.ID) === pendingPicks[`event_id${pickNum + 1}`]);
                if (event) {
                    if (picks[`Pick${Number(pickNum) + 1}`] === event.Pick2) {
                        picks[`Pick${Number(pickNum) + 1}`] = event.Pick1;
                    } 
                    else {
                        picks[`Pick${Number(pickNum) + 1}`] = event.Pick2;
                    }
                    picks[`Spread${Number(pickNum) + 1}`] = null;
                }
            }
        });
        setPendingPicks(picks);
    }
    
    function handleRandomClick() {
        const picks = {...pendingPicks};
        const availableSpreadPicks = availablePicks.filter((pick) => pick.Spread !== null);
        [...Array(5).keys()].forEach((pickNum) => {
            if (currentPicks[`Spread${pickNum+1}`] === null || currentPicks[`Spread${pickNum+1}`] === undefined) {
                const randomIdx = Math.floor(Math.random() * availableSpreadPicks.length);
                picks[`Pick${Number(pickNum) + 1}`] = availableSpreadPicks[randomIdx].Pick;
                picks[`event_id${Number(pickNum) + 1}`] = Number(availableSpreadPicks[randomIdx].event_id);
                picks[`Spread${Number(pickNum) + 1}`] = null;
            }
        });
        setPendingPicks(picks);
    }

    function handleEventFilterChange(e) {
        setEventFilter(e.target.value);
        processEvents(e.target.value, props.getState().footballmakepicks.data.events.data);
    }
    
    function handlePickChange(e) {
        const pickInfo = e.target.value.split('|');
        const newPicks = {...pendingPicks};
        newPicks[`Pick${pickInfo[0]}`] = pickInfo[1];
        newPicks[`event_id${pickInfo[0]}`] = Number(pickInfo[2]);
        setPendingPicks(newPicks);
    }
    
    function dayFormat(day) {
        return fixupTime(day.getHours(), day.getMinutes());
    }
    
    function isTeamSelected(team, event_id) {
        return (
            (pendingPicks.Pick1 === team && pendingPicks.event_id1 === event_id) ||
            (pendingPicks.Pick2 === team && pendingPicks.event_id2 === event_id) ||
            (pendingPicks.Pick3 === team && pendingPicks.event_id3 === event_id) ||
            (pendingPicks.Pick4 === team && pendingPicks.event_id4 === event_id) ||
            (pendingPicks.Pick5 === team && pendingPicks.event_id5 === event_id)
        );
    }
    
    function submitButtonDisabled() {
        return JSON.stringify(currentPicks) === JSON.stringify(pendingPicks);
    }
    
    function isPickLocked(pickNum) {
        if (!currentPicks || currentPicks[`Pick${pickNum+1}`] === null || currentPicks[`Pick${pickNum+1}`] === undefined) {
            return false;
        }
        if (currentPicks[`Spread${pickNum+1}`] !== null && currentPicks[`Spread${pickNum+1}`] !== undefined) {
            return true;
        }
        const pickEvent = availablePicks.filter((event) => Number(event.event_id) === currentPicks[`event_id${pickNum+1}`]).pop();
        return getNewDate(pickEvent.Day) < (new Date());
    }
    
    function handleLockedChange(e) {
        const picks = {...pendingPicks};
        const pickNum = e.target.id.split('-')[1];
        if (e.target.checked) {
            const pickEvent = availablePicks.filter((event) => Number(event.event_id) === picks[`event_id${pickNum}`] && event.Pick === picks[`Pick${pickNum}`]).pop();
            picks[`Spread${pickNum}`] = pickEvent.Spread;
        }
        else {
            picks[`Spread${pickNum}`] = null;
        }
        setPendingPicks(picks);
    }
    
    function renderData() {
        return (
            <div className="FootballMakePicks">
                <h4>Pick 5 Football Picks Week {config.Week}</h4>
                <div className="FootballMakePicksTopRow">
                    <div>
                        {[...Array(5).keys()].map((pickNum) => {
                            return (
                                <div className="FootballMakePicksPickContainer">
                                    <div className="FootballMakePicksPickLabel">Pick {pickNum+1}:</div>
                                    <div className="FootballMakePicksPickSelectContainer">
                                        <Select
                                            size="small"
                                            fullWidth
                                            value={pendingPicks ? `${pickNum+1}|`+pendingPicks[`Pick${pickNum + 1}`]+`|`+pendingPicks[`event_id${pickNum+1}`] : '0'}
                                            onChange={handlePickChange}
                                            disabled={isPickLocked(pickNum)}
                                        >
                                            {availablePicks
                                                .filter((pick) => pick.Spread !== null || pick.event_id === currentPicks[`event_id${pickNum+1}`])
                                                .map((pick) => {
                                                    let pString;
                                                    if (Number(pick.event_id) === currentPicks[`event_id${pickNum+1}`] && currentPicks[`Spread${pickNum+1}`] != null) {
                                                        pString = pickString(pick.Pick, currentPicks[`Spread${pickNum+1}`]);
                                                    }
                                                    else {
                                                        pString = pickString(pick.Pick, pick.Spread);
                                                    }
                                                    return <MenuItem key={`Pick${pickNum}${pick.Pick}`}
                                                              value={`${pickNum + 1}|${pick.Pick}|${pick.event_id}`}>{pString}</MenuItem>
                                                }    
                                            )}
                                        </Select>
                                    </div>
                                    <FormGroup>
                                        <FormControlLabel control={
                                            <Checkbox 
                                                checked={pendingPicks ? (pendingPicks[`Spread${pickNum+1}`] !== null && pendingPicks[`Spread${pickNum+1}`] !== undefined) : false}
                                                disabled={isPickLocked(pickNum)}
                                                onChange={handleLockedChange}
                                                id={`locked-${pickNum+1}`}
                                            />
                                        } label="Locked" labelPlacement="start"/>
                                    </FormGroup>
                                </div>
                            );
                        })}
                    </div>
                    <div>
                        <div className="FootballMakePicksTopButtonAndTextContainer">
                            <div className="FootballMakePicksTopButtonContainer">
                                <Button variant="outlined" onClick={handleRandomClick}>Random Picks!</Button>
                                <Button variant="outlined" onClick={handleCostanzaClick}>Costanza!</Button>
                            </div>
                            <p className="FootballMakePicksPText">* The Locked button can be used to lock a pick & spread before the advertised game time. Once locked, the pick and spread will not change.</p>
                            <p className="FootballMakePicksPText">* Spreads are updated every hour on the hour. Refresh this page to ensure you're viewing current spreads.</p>
                        </div>
                    </div>
                </div>
                <div className="FootballMakePicksMiddleRow">
                    <Button variant="outlined" onClick={handleSubmitPicksClick} disabled={submitButtonDisabled()}>Submit Picks</Button>
                    <div className="FootballMakePicksSubmitResult">{submitPicksResult}</div>
                </div>
                <Link className="ContestLink" to="./.." key='link-mainpage'>Return to the main Page</Link>
                <div className="FootballMakePicksEventHeaderRow">
                    <div>
                        <h4>Available Games</h4>
                    </div>
                    <div>
                        <ToggleButtonGroup
                            value={eventFilter}
                            exclusive
                            onChange={handleEventFilterChange}
                            aria-label="event filter"
                        >
                            <ToggleButton value="ALL" aria-label="left aligned">All</ToggleButton>
                            <ToggleButton value="NFL" aria-label="centered">NFL</ToggleButton>
                            <ToggleButton value="NCAA" aria-label="right aligned">NCAA</ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                </div>
                <div className="FootballMakePicksDayContainer">
                    {Object.entries(events).map(([dayName, dayEvents]) => (
                        dayEvents.filter((event) => getNewDate(event.Day) > (new Date())).length === 0 ? '' : (
                        <div className="FootballMakePicksDayColumn">
                            <h5>{dayName}</h5>
                            <TableContainer component={Paper} className="FootballMakePicksPickTableContainer">
                                <Table size="small" className="FootballMakePicksPicksTable">
                                    <TableBody>
                                        {dayEvents
                                            .filter((event) => getNewDate(event.Day) > (new Date()) && event.Spread1 !== null)
                                            .map((event) =>
                                            <TableRow>
                                                <TableCell>
                                                    <FootballEventCell
                                                        team1={pickString(event.Pick2, event.Spread2)}
                                                        team2={pickString(event.Pick1, event.Spread1)}
                                                        score1={0}
                                                        score2={0}
                                                        inProgress={false}
                                                        started={false}
                                                        status=''
                                                        tv={event.TV}
                                                        day={event.Day}
                                                        dayFormat={dayFormat}
                                                        team1Selected={isTeamSelected(event.Pick2, Number(event.ID))}
                                                        team2Selected={isTeamSelected(event.Pick1, Number(event.ID))}
                                                        eventId={Number(event.ID)}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    )
                    ))}
                </div>
                <a href="events.csv"><img src="csv_file.png"/></a>
            </div>
        );
    }
    
    return (
        <div>
            {isLoading ? renderLoading() : renderData()}
        </div>
    );
}

export default FootballMakePicks;